<template>

</template>

<script>
export default {
  name: "ReferralCode",
  props: ["code"],
  mounted() {
    //let codeExists = this.$cookies.isKey("roy-spitzner-referral-code");
    let existingCode = this.$store.getters.referralCode;
    console.log("existing code: " + existingCode);
    if (!existingCode) {
      this.$store.dispatch("updateReferralCode", this.code)
      this.$store.dispatch("postRequest", ["referral-code-click/" + this.code])
      //this.$cookies.set("roy-spitzner-referral-code", this.code)
    }
    this.$router.push("/")
  }
};
</script>

<style scoped>

</style>